import ApiResource from "@/includes/resource";

class UsersResource extends ApiResource {
    constructor() {
        super('users')
    }

    me(path) {
        return this.get('/api/user', {
            path: path
        })
    }

    async login(credentials) {
        return await this.post('/login', credentials)
    }

    async tgLogin(credentials) {
        return await this.post('/api/tgLogin', credentials)
    }

    async loginAs(credentials) {
        return await this.post('/api/loginAs', credentials)
    }

    async loginAsBack() {
        return await this.post('/api/login-as-back')
    }

    async register(data) {
        return await this.post('/register', data)
    }

    async tgCheck(data) {
        return await this.get('/api/tgCheck', data)
    }

    async autoLoginRequest() {
        let query = new URLSearchParams(window.location.search)
        let path = new URL(window.location)

        let params = []
        if (query.get('hash')) {

            params['tgData'] = {}
            params['tgData']['hash'] = query.get('hash')
            params['tgData']['auth_date'] = query.get('auth_date')
            params['tgData']['first_name'] = query.get('first_name')
            params['tgData']['id'] = query.get('id')
            params['tgData']['last_name'] = query.get('last_name')
            params['tgData']['photo_url'] = query.get('photo_url')
            params['tgData']['username'] = query.get('username')

            if (query.get('filter'))
                params['filter'] = query.get('filter')

            params['path'] = path.pathname
        }

        if (query.get('redirect')) {
            params['redirect'] = query.get('redirect')
        }

        return await this.post('/autologin', {...params})
    }

    async workPizzerias(user_id, week_start_date) {
        return await this.get(`api/users/${user_id}/courierWorkPizzerias`, {
            start_date: week_start_date
        })
    }

    async logout() {
        return await this.post('/logout')
    }
}

export default new UsersResource()
