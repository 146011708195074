import ApiResource from "@/includes/resource";

class CashReportsResource extends ApiResource {
    constructor() {
        super('pizzerias')
    }

    async cities(params) {
        return await this.get('/api/pizzerias/cities', params)
    }

}

export default new CashReportsResource()